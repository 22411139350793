'use strict';

$(
	function () {
		try {
			var topH = $('._ > .top').height();
			app.fixTop = topH || 0;
		} catch (e) {}
		app.assignNumberControl();
		app.setSelectedLink();
		app.addRegisterCheckBoxes();
		app.attachSvgEvents();
		app.initACBL();
		app.setBackUrls();
		app.addCoverSpan();
		app.saveProductListInfo();
		app.topMenu.init();
		if (!app.isMobile()) {
			$(window).scroll(
				function () {
					app.floatingHeader();
				}
			);
			app.floatingHeader();
		}
		app.assignProductOver();
		app.initializeScrollButton();
		app.hamburger.init({
			look: 1
		});
		app.color.init();
		app.extraMenu && app.extraMenu.init({side: 'left'});
		app.template.set();
	}
);

var app = {
	_VERSION: 2,
	_vars: {
		v: 1.4,
		tmp: 26,
		putAttributesToProdItemsDirectly: true,
		_guid: '5d57a056-6a76-47f3-8a9f-7ad2820ceb92',
		_customFonts: {
			Linearicons: true
		}
	},
	responsiveMode: true,
	indexLinkPos: 0,
	messages: {},
	productAvailableQuantityUrl: null,
	optionsWereInitialized: false,
	waitBlockZIndex: 9999,
	template: {
		set: function () {
			this.loginSuccessCallBack();
		},
		loginSuccessCallBack: function () {
			try {
				app.events.addListener('udu_LOGIN-SUCCESS', function () {
					app.lang.getWord(
						'_My_Account',
						function (str) {
							$('.top .log-in').text(str);
						}
					);
					$('.top .reg-me').remove();
				});
			} catch (e) {
			}
		}
	}
};